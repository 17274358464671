<template>
  <v-card flat>
    <v-container grid-list-md>
      <v-row no-gutters>
        <v-col cols="12" md="12" xs="12" sm="12" class="mb-2 v-col-padding">
          <v-select
            :items="regions"
            :label="$vuetify.lang.t('$vuetify.account.address_dialog.region')"
            :prepend-icon="prependIcon ? 'mdi-map-marker' : null"
            required
            outlined
            hide-details
            v-model="searchRegion"
            item-text="name"
            item-value="schema"
            class="custom-select-box"
            @change="clearLocalData"
            tabindex="1"
          />
        </v-col>
        <v-col cols="12" md="12" xs="12" sm="12" class="mb-2 v-col-padding">
          <v-text-field
            label="Особовий рахунок"
            prepend-icon="mdi-numeric"
            required
            outlined
            hide-details
            v-model.trim="personIdInternal"
            @keyup.enter="searchByPersonIdInternal"
            :disabled="!searchRegion"
            @input="clearLocalData"
            tabindex="2"
          />
        </v-col>
<!--        <v-col cols="12" md="12" xs="12" sm="12" class="mb-2 v-col-padding" v-if="specificationList.length > 0">-->
<!--          <v-select-->
<!--            :items="specificationList"-->
<!--            item-text="name"-->
<!--            item-value="secret_id"-->
<!--            label="Оберіть необхідну організацію"-->
<!--            prepend-icon="mdi-note-outline"-->
<!--            required-->
<!--            outlined-->
<!--            hide-details-->
<!--            v-model="secret_id"-->
<!--            tabindex="3"-->
<!--          />-->
<!--        </v-col>-->
        <v-col cols="12" md="12" xs="12" sm="12" class="mb-2 v-col-padding" v-if="specificationList.length > 0">
          <v-select
              :items="specificationList"
              item-text="name"
              item-value="id"
              label="Оберіть необхідну організацію"
              prepend-icon="mdi-note-outline"
              required
              outlined
              hide-details
              v-model="person_id"
              tabindex="3"
          />
        </v-col>
        <v-col cols="12" class="v-col-padding" md="12" xs="12" sm="1" v-if="searchByPersonError">
            <v-alert text type="warning" color="success" class="ml-8">
              Введений Вами особовий рахунок <b>{{ personIdInternal }}</b> не знайдено
            </v-alert>
        </v-col>
        <v-col cols="12" class="v-col-padding" md="12" xs="12" sm="12">
            <div style="width: 100%;" class="pl-8">
              <v-btn height="48px" block class="success ma-0"
                     :loading="loading"
                     :disabled="!personIdInternal" @click="searchByPersonIdInternal" tabindex="4">
                Знайти
              </v-btn>
            </div>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
  import bills from '../../../public/files/i_pay/bills.json'
  import {mapState} from 'vuex'
  import {createHelpers} from 'vuex-map-fields';
  import {HTTPError} from '@/lib/http'
  import {
    SEARCH_ACCOUNT_LOCALITY_REGIONS,
    SEARCH_ACCOUNT_CLEAR,
  } from '@/store/modules/searchAccount/mutation-types';
  import { SearchApi } from '@/api/search'
  import { map, assign } from 'lodash'
  const jsonBig = require('json-bigint');

  const { mapFields } = createHelpers({
      getterType: `getSearchAccountField`,
      mutationType: `updateSearchAccountField`,
  });

  export default {
    name: "SearchByPersonIdInternal",
    props: {
        redirect: {
            type: Boolean,
            default: true
        },
        prependIcon: {
          type: Boolean,
          default: true,
        }
    },
    data () {
      return {
        personIdInternal: '',
        secret_id: '',
        person_id: null,
        loading: false,
        searchByPersonError: false,
        specificationList: [],
      }
    },
    computed: {
        ...mapFields([
            'searchForm.searchAddress.searchRegion',
        ]),
      ...mapState({
        regions: state => state.searchAccount.searchRegions,
      }),
      bill_ids() {
          return Object.assign(bills)
      }
    },
    methods: {
      getRegions() {
        try {
          return this.$store.dispatch(SEARCH_ACCOUNT_LOCALITY_REGIONS);
        } catch (error) {
          if (!(error instanceof HTTPError)) this.$snackbar(error.message, 'error');
        }
      },
      clearLocalData(){
        this.specificationList = [];
        this.secret_id = '';
        this.person_id = null
        this.searchByPersonError = false;
      },
      redirectToEasyPay() {
        const i_pay_url = this.bill_ids[this.searchRegion] || null
        console.log(i_pay_url, this.bill_ids, this.searchRegion)
        if (!i_pay_url) {
          this.$snackbar("Помилка BILL_ID", 'error')
          return;
        }
        this.loading = false
        window.location.href = `${i_pay_url}${this.person_id}`
      },
      redirectToEk(){
        if (this.redirect) {
            this.$router.push({ name: 'not-authenticated-account-view', params: { schema: this.searchRegion, secret_id: this.secret_id } });
        } else {
            const address = {
                schema: this.searchRegion,
                secret_id: this.secret_id
            };
            this.$emit('getSecretIdByPersonIdInternal', address)
        }
      },
      async getSecretId() {
        this.loading = true
        try {
          let {data} = await SearchApi.byPersonIdInternal(this.searchRegion, this.personIdInternal);
          let data_bigint = jsonBig.parse(data)
          data = JSON.parse(data)

          const multiple  = map(data_bigint.multiple, (item) => {
            return assign(item, {
              name: `${item.organization_name} (${item.service_name})`,
              id: item.id ? item.id.toString() : null
            })
          });

          this.searchByPersonError = false;
          this.specificationList = multiple || [];
          this.secret_id = data.secret_id;
          this.person_id = data_bigint.person_id ? data_bigint.person_id.toString() : null

          if (this.person_id) {
            this.redirectToEasyPay();
            // this.redirectToEk();
            return;
          }
          if (!this.specificationList.length) this.searchByPersonError = true;
        } catch (e) {
            this.$snackbar("Помилка пошуку", 'error')
        } finally {
          this.loading = false
        }
      },
      searchByPersonIdInternal(){
        if (this.person_id) {
          this.loading = true
          this.redirectToEasyPay();
          this.secret_id = '';
          this.person_id = null
          this.specificationList = [];
          this.personIdInternal = '';
          return;
        }
        this.getSecretId();
      },
    },
    mounted() {
      this.$store.commit(SEARCH_ACCOUNT_CLEAR);
      this.getRegions();
    },
  }
</script>

<style scoped lang="scss">
  .container-max-width {
    max-width: 1560px;
  }
  .v-col-padding {
    padding-top: 5px;
    padding-bottom: 5px;
  }
</style>
