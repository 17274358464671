<template>
  <v-card flat>
    <v-container grid-list-sm>
      <v-row>
        <v-col cols="12" md="12" sm="12" xs="12" class="mb-2 v-col-padding">
          <v-select
            :items="regions"
            :label="$vuetify.lang.t('$vuetify.account.address_dialog.region')"
            :prepend-icon="prependIcon ? 'mdi-map-marker' : null"
            required
            outlined
            hide-details
            v-model="searchRegion"
            item-text="name"
            item-value="schema"
            @change="clearLocalData"
            tabindex="1"
          />
        </v-col>
        <v-col cols="12" md="12" sm="12" xs="12" class="mb-2 v-col-padding">
          <LocalityAutocomplete
            :schema="searchRegion"
            :disabled="!searchRegion"
            :searchForm="true"
            :showPrependIcon = "prependIcon"
            @change="mapLocalityFormField"
          />
        </v-col>
        <v-col cols="12" md="12" sm="12" xs="12" class="mb-2 v-col-padding">
          <StreetAutocomplete
            :schema="searchRegion"
            :locality="locality"
            :disabled="!locality"
            :searchForm="true"
            :showPrependIcon = "prependIcon"
            @change="mapStreetFormField"
            tabindex="3"
          />
        </v-col>
        <v-col cols="6" md="6" sm="6" xs="6" class="mb-2 v-col-padding">
          <v-text-field
            :label="$vuetify.lang.t('$vuetify.account.address_dialog.building')"
            type="tel"
            required
            :prepend-icon="prependIcon ? 'mdi-map-marker' : null"
            outlined
            hide-details
            v-model.number="searchBuilding"
            :disabled="!street"
            @keyup.enter="getSecretIdByAddress"
            @input="clearLocalData"
            @keydown="numberFormat"
            tabindex="4"
          />
        </v-col>
        <v-col cols="6" md="6" sm="6" xs="6" class="mb-2 v-col-padding">
          <v-text-field
            :label="$vuetify.lang.t('$vuetify.account.address_dialog.corpus')"
            required
            outlined
            hide-details
            v-model.trim="searchCorpus"
            :disabled="!searchBuilding"
            @input="clearLocalData"
          />
        </v-col>
        <v-col cols="6" md="6" sm="6" xs="6" class="mb-2 v-col-padding">
          <v-text-field
            :label="$vuetify.lang.t('$vuetify.account.address_dialog.apartment')"
            type="tel"
            :prepend-icon="prependIcon ? 'mdi-map-marker' : null"
            required
            outlined
            hide-details
            v-model.number="searchApartment"
            :disabled="!searchBuilding"
            @input="clearLocalData"
            @keyup.enter="getSecretIdByAddress"
            @keydown="numberFormat"
            tabindex="5"
          />
        </v-col>
        <v-col cols="6" md="6" sm="6" xs="6" class="mb-2 v-col-padding">
          <v-text-field
            :label="$vuetify.lang.t('$vuetify.account.address_dialog.block')"
            required
            outlined
            hide-details
            v-model.trim="searchBlock"
            :disabled="!searchApartment"
            @input="clearLocalData"
          />
        </v-col>
        <v-col cols="12" class="v-col-padding" md="12" xs="12" sm="1" v-if="searchByAddressError">
          <v-alert  text type="warning" color="success" class="ml-8">
            За такими параметрами адреси не знайдено
          </v-alert>
        </v-col>
        <v-col cols="12" class="v-col-padding" md="12" sm="12" xs="12">
          <div style="width: 100%;" class="pl-8">
            <v-btn height="48px" block class="success ma-0 pl-11"
                   :loading="loading"
                   :disabled="!searchBuilding" @click="getSecretIdByAddress" tabindex="6">
              Знайти
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
  import bills from '../../../public/files/i_pay/bills.json'

  import {mapState} from 'vuex'
  import {createHelpers} from 'vuex-map-fields';
  import {HTTPError} from '@/lib/http'
  import {
    SEARCH_ACCOUNT_LOCALITY_FORM_ADDRESS_LOCALITY,
    SEARCH_ACCOUNT_LOCALITY_FORM_ADDRESS_STREET,
    SEARCH_ACCOUNT_LOCALITY_REGIONS,
    SEARCH_ACCOUNT_CLEAR,
  } from '@/store/modules/searchAccount/mutation-types';
  import LocalityAutocomplete from '@/components/locality/LocalityAutocomplete'
  import StreetAutocomplete from '@/components/locality/StreetAutocomplete'
  import { SearchApi } from '@/api/search'

  const jsonBig = require('json-bigint');

  const { mapFields } = createHelpers({
    getterType: `getSearchAccountField`,
    mutationType: `updateSearchAccountField`,
  });

  export default {
    name: "SearchView",
    props: {
        redirect: {
            type: Boolean,
            default: true
        },
        prependIcon: {
          type: Boolean,
          default: true,
        }
    },
    components: {
      LocalityAutocomplete,
      StreetAutocomplete,
    },
    data () {
      return {
        secret_id: '',
        person_id: null,
        searchByAddressError: false,
        loading: false
      }
    },
    computed: {
      ...mapFields([
        'searchForm.searchAddress.searchRegion',
        'searchForm.searchAddress.searchBuilding',
        'searchForm.searchAddress.searchCorpus',
        'searchForm.searchAddress.searchApartment',
        'searchForm.searchAddress.searchBlock',
      ]),
      ...mapState({
        regions: state => state.searchAccount.searchRegions,
        locality: state => state.searchAccount.searchForm.searchAddress.searchLocality,
        street: state => state.searchAccount.searchForm.searchAddress.searchStreet,
      }),
      bill_ids() {
        return Object.assign(bills)
      }
    },
    methods: {
      numberFormat(e) {
        if ((e.keyCode === 9) || (e.keyCode === 13) || (e.keyCode === 8)) {
          return true;
        }

        if (e.shiftKey) e.preventDefault();

        let charCode = (e.which) ? e.which : event.keyCode;
        let keyNumbers = [48,49,50,51,52,53,54,55,56,57,96,97,98,99,100,101,102,103,104,105];

        if (!keyNumbers.includes(charCode)) {
          e.preventDefault();
          return false;
        }
      },
      getRegions() {
        try {
          return this.$store.dispatch(SEARCH_ACCOUNT_LOCALITY_REGIONS);
        } catch (error) {
          if (!(error instanceof HTTPError)) this.$snackbar(error.message, 'error');
        }
      },
      mapLocalityFormField(locality) {
        if(!locality) {
          this.$store.commit(SEARCH_ACCOUNT_LOCALITY_FORM_ADDRESS_LOCALITY, null);
          return;
        }
        this.$store.commit(SEARCH_ACCOUNT_LOCALITY_FORM_ADDRESS_LOCALITY, locality.id);
        this.clearLocalData();
      },
      mapStreetFormField(street) {
        if(!street) {
          this.$store.commit(SEARCH_ACCOUNT_LOCALITY_FORM_ADDRESS_STREET, null);
          return;
        }
        this.$store.commit(SEARCH_ACCOUNT_LOCALITY_FORM_ADDRESS_STREET, street.id);
        this.clearLocalData();
      },
      clearLocalData(){
        this.specificationList = [];
        this.secret_id = '';
        this.person_id = null
        this.searchByAddressError = false;
      },
      redirectToEk(){
        if (this.redirect) {
            this.$router.push({ name: 'not-authenticated-account-view', params: { schema: this.searchRegion, secret_id: this.secret_id } });
        } else {
            const address = {
                schema: this.searchRegion,
                secret_id: this.secret_id
            };
            this.$emit('getSecretIdByAddress', address)
        }
      },
      redirectToEasyPay() {
        const i_pay_url = this.bill_ids[this.searchRegion] || null
        if (!i_pay_url) {
          this.$snackbar("Помилка BILL_ID", 'error')
          return;
        }
        this.loading = false
        window.location.href = `${i_pay_url}${this.person_id}`
      },
      async getSecretIdByAddress() {
        this.loading = true
        try {
          let {data} = await SearchApi.byAdress(this.searchRegion, this.locality, this.street, this.searchBuilding, this.searchCorpus ? this.searchCorpus : null, this.searchApartment, this.searchBlock ? this.searchBlock : null);
          this.searchByAddressError = false;
          this.secret_id = data.secret_id;
          this.person_id = data.person_id

          let data_bigint = jsonBig.parse(data)

          this.person_id = data_bigint.person_id ? data_bigint.person_id.toString() : null

          if (this.person_id) {
            this.redirectToEasyPay();
          } else {
            this.searchByAddressError = true;
          }
        } catch (e) {
            this.$snackbar("Помилка пошуку по адресі", 'error')
        } finally {
          this.loading = false
        }
      },
    },
    mounted() {
      this.$store.commit(SEARCH_ACCOUNT_CLEAR);
      this.getRegions();
    },
  }
</script>

<style scoped lang="scss">
  .container-max-width {
    max-width: 1560px;
  }
  .v-col-padding {
    padding-top: 5px;
    padding-bottom: 5px;
  }
</style>
