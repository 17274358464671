<template>
  <v-autocomplete
    :items="searchForm ? searchStreets : streets"
    :outlined="searchForm"
    :prepend-icon="searchForm ? showPrependIcon ? 'mdi-city' : '' : ''"
    :label="$vuetify.lang.t('$vuetify.account.address_dialog.street')"
    :loading="loading"
    :placeholder="$vuetify.lang.t('$vuetify.account.address_dialog.start_typing')"
    :search-input.sync="search"
    item-text="name"
    required
    v-model="street"
    clearable
    no-filter
    :disabled="disabled"
    hide-no-data
    :hide-details="searchForm"
    return-object
    @change="updateFormField"
    tabindex="3"
  />
</template>

<script>
  import {debounce} from 'lodash'
  import {mapState} from 'vuex'
  import {HTTPError} from '@/lib/http'
  import {ACCOUNT_LOCALITY_STREET_SEARCH} from '@/store/modules/account/mutation-types';
  import {SEARCH_ACCOUNT_LOCALITY_STREET} from '@/store/modules/searchAccount/mutation-types';

  const QUERY_MIN_LENGTH = 3;
  const SEARCH_DEBOUNCE_TIME = 400;

  export default {
    name: 'StreetAutocomplete',
    data() {
      return {
        search: null,
        loading: false,
        street: null
      }
    },
    props: {
      schema: {
        type: String,
        required: false
      },
      locality: {
        type: Number,
        required: false
      },
      disabled: {
        type: Boolean,
        default: true
      },
      searchForm: {
        type: Boolean,
        default: false
      },
      showPrependIcon: {
        type: Boolean,
        default: true
      }
    },
    methods: {
      makeSearch: debounce(function (schema, locality_id, query) {
        if (this.loading) return;

        this.loading = true;

        // API call
        try {
          if (this.searchForm) {
            return this.$store.dispatch(SEARCH_ACCOUNT_LOCALITY_STREET, {schema, locality_id, query});
          } else {
            return this.$store.dispatch(ACCOUNT_LOCALITY_STREET_SEARCH, {schema, locality_id, query});
          }
        } catch (error) {
          if (!(error instanceof HTTPError)) this.$snackbar(error.message, 'error');
        } finally {
          this.loading = false;
        }
      }, SEARCH_DEBOUNCE_TIME),
      updateFormField(street) {
        this.$emit('change', street);
      }
    },
    computed: {
      ...mapState({
        streets: state => state.account.streets,
        searchStreets: state => state.searchAccount.searchStreets,
      })
    },
    watch: {
      search(query) {
          if (this.schema
            && this.locality
            && query
            && query.length >= QUERY_MIN_LENGTH
            && !(this.street && this.street.name === query)
          ) this.makeSearch(this.schema, this.locality, query);
      },
      // schema(schema) {
      //   this.preloadData(schema);
      // }
    }
  }
</script>
