import http from '@/lib/http'

const SEARCH_URL = '/search';

// const jsonBig = require('json-bigint');

const SearchApi = {
  byPersonIdInternal(schema, person_id_internal) {
    return http.post(
      SEARCH_URL,
      {
        schema,
        type: 'id',
        person_id_internal
      },
        {
            transformResponse: [data => data],
        }

    )
  },
  byAdress(schema, locality_id, street_id, building, corpus, apartment, block) {
    return http.post(
      SEARCH_URL,
      {
        schema,
        type: 'address',
        locality_id,
        street_id,
        building,
        corpus,
        apartment,
        block
      },
        {
            transformResponse: [data => data],
        }
    )
  },
};

export {
  SearchApi,
};

